import Web3 from 'web3';
import chainsDb from './chainsDb';
import lastWordContract from './lastWordContract';
const availableNetworks = (process.env.VUE_APP_AVAILABLE_NETWORKS || '').split(',').map(v => Number(v));

export default {
  namespaced: true,
  state: {
    connected: false,
    chainId: window?.ethereum?.networkVersion || availableNetworks[0] || 1,
    account: null,
    balance: null,
    web3: null
  },
  getters: {
    available: (state) => {
      return (window.ethereum !== undefined) || (!!chainsDb[availableNetworks[0]]?.rpc);
    },
    readonly: (date) => {
      return (window.ethereum === undefined) && (!!chainsDb[availableNetworks[0]]?.rpc);
    },
    chain: (state) => {
      return chainsDb[state.chainId];
    },
    fromWei: () => (value, unit) => {
      if (value === null) {
        return value;
      }
      return Number(Web3.utils.fromWei(`${value}`, unit));
    },
    toWei: () => (value, unit) => {
      if (value === null) {
        return value;
      }
      return Web3.utils.toWei(`${value}`, unit);
    }
  },
  mutations: {
    setConnected (state, connected) {
      state.connected = connected;
    },
    refreshState (state, data) {
      state.account = data.account;
      state.balance = data.balance;
      state.chainId = data.chainId;
    },
    setWeb3 (state, web3) {
      state.web3 = web3;
    }
  },
  actions: {
    async connect ({ state, commit, getters, dispatch }) {
      let web3 = null;
      if (!getters.available) {
        throw new Error('Ethereum is not available in this browser.');
      }
      if (state.connected) {
        return;
      }
      if (window.ethereum) {
        try {
          if (window.ethereum.request) {
            await window.ethereum.request({ method: 'eth_requestAccounts' });
          } else {
            await window.ethereum?.enable();
          }
        } catch (err) {
          if (err.code !== 4001) {
            throw err;
          }
          return;
        }
        web3 = new Web3(window.ethereum);
      } else {
        const chain = getters.chain;
        const url = chain?.rpc?.http;
        const provider = new Web3.providers.HttpProvider(url);
        web3 = new Web3(provider);
      }
      commit('setWeb3', web3);
      const emitter = window.ethereum?.on
        ? window.ethereum
        : web3.eth.subscribe('logs');

      emitter.on('accountsChanged', async (accounts) => {
        try {
          await dispatch('refreshState');
        } catch (e) {
          console.error('accountsChanged: refreshState fails', e);
        }
      });
      emitter.on('chainChanged', async (chainId) => {
        try {
          await dispatch('refreshState');
        } catch (e) {
          console.error('chainChanged: refreshState fails', e);
        }
      });
      const r = await dispatch('refreshState');
      commit('setConnected', true);
      return r;
    },
    async refreshState ({ state, commit, dispatch }) {
      console.log('refreshState');
      const [accounts, chainId] = await Promise.all([
        state.web3.eth.getAccounts(),
        state.web3.eth.getChainId()
      ]);
      console.log('GOT', { accounts, chainId });
      let balance = 0;
      if (accounts.length) {
        balance = await state.web3.eth.getBalance(accounts[0]);
      }

      const data = {
        account: accounts[0] || null,
        balance,
        chainId
      };
      commit('refreshState', data);
      console.log('web3/refreshState', data);
      await dispatch('lastWordContract/refreshState');
      return data;
    }
  },
  modules: {
    lastWordContract
  }
};
