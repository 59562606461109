const infuraId = process.env.VUE_APP_INFURA_PROJECT_ID;

export default {
  91919: {
    name: 'Local Testnet',
    network: 'local',
    networkId: 91919,
    rpc: {
      http: 'http://127.0.0.1:7545'
    },
    explorer: null
  },
  1: {
    name: 'Ethereum Mainnet',
    chainId: 1,
    network: 'mainnet',
    rpc: infuraId && {
      http: `https://mainnet.infura.io/v3/${infuraId}`,
      wss: `wss://mainnet.infura.io/ws/v3/${infuraId}`
    },
    explorer: {
      name: 'etherscan',
      url: 'https://etherscan.io'
    }
  },
  3: {
    name: 'Ropsten Testnet',
    chainId: 3,
    network: 'ropsten',
    rpc: infuraId && {
      http: `https://ropsten.infura.io/v3/${infuraId}`,
      wss: `wss://ropsten.infura.io/ws/v3/${infuraId}`
    },
    explorer: {
      name: 'etherscan',
      url: 'https://ropsten.etherscan.io'
    }
  },
  4: {
    name: 'Rinkeby Testnet',
    chainId: 4,
    network: 'rinkeby',
    rpc: infuraId && {
      http: `https://rinkeby.infura.io/v3/${infuraId}`,
      wss: `wss://rinkeby.infura.io/ws/v3/${infuraId}`
    },
    explorer: {
      name: 'etherscan',
      url: 'https://rinkeby.etherscan.io'
    }
  }
};
