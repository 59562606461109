<template>
  <div class="last-word--page">
    <h1>Buy The Last Word</h1>
    <div v-if="showResult">
      <div class="result-frame">
        <div v-if="txResult.error">
          <p class="result-text">
            {{txResult.error.message}}
          </p>
          <button class="btn" @click="onReset">
            Try again
          </button>
        </div>
        <div v-else>
          <p class="result-text">
            Congratulations, you have the Last Word.
          </p>
          <button class="btn" @click="onCancel">
            Continue
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <form class="last-word--form"
        @submit.prevent="onSubmit" >
        <fieldset>
          <section>
            <label for="newLastWord">What do you want to say?</label>
            <textarea id="newLastWord" v-model.trim="newLastWord" rows="8" maxlength="255"></textarea>
            <div style="text-align: right">
              <span>{{newLastWord?.length || 0}}/255</span>
            </div>
          </section>
          <section>
            <label for="newPrice"> How much will you pay?</label>
            <div class="price-input--wrapper">
              <input id="newPrice" type="text" class="price-input eth" v-model.number="newPrice" />
              <span class="eth">ETH</span>
            </div>
          </section>
          <section class="btn-bar">
            <button class="btn cancel" @click="onCancel">
              Cancel
            </button>
            <input class="btn submit" type="submit" value="Submit"
              :disabled="disableSubmit" />
          </section>
        </fieldset>
      </form>
    </div>
    <div v-if="showPending" class="pending">
      <div class="loader__wrap">
        <div class="loader"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'Buy',
  computed: {
    ...mapGetters({
      available: 'blockchain/available',
      readonly: 'blockchain/readonly',
      chain: 'blockchain/chain',
      fromWei: 'blockchain/fromWei',
      toWei: 'blockchain/toWei'
    }),
    ...mapState({
      connected: state => state.blockchain.connected,
      account: state => state.blockchain.account,
      balance: state => state.blockchain.balance,
      lastWord: state => state.blockchain.lastWordContract
    }),
    disableSubmit: function () {
      if (this.newPrice < this.fromWei(this.lastWord.price)) {
        return true;
      }
      if (!this.newLastWord) {
        return true;
      }
      if ((this.newLastWord === this.lastWord.content) &&
      (this.account === this.lastWord.author)) {
        return true;
      }
      return false;
    }
  },
  data () {
    return {
      showPending: false,
      showResult: false,
      txResult: null,
      newLastWord: null,
      newPrice: null
    };
  },
  watch: {
    'lastWord.price': function (price) {
      console.log('watch:', price);
      const p = this.fromWei(price);
      if ((this.newPrice === null) || (this.newPrice <= p)) {
        this.newPrice = p;
      }
    }
  },
  methods: {
    ...mapActions({
      setLastWord: 'blockchain/lastWordContract/setLastWord'
    }),
    async onSubmit () {
      console.log('CALLED SUBMIT');
      this.showPending = true;
      try {
        this.txResult = await this.setLastWord({
          content: this.newLastWord,
          author: this.account,
          price: this.toWei(this.newPrice)
        });
      } catch (error) {
        const m = error.message.match(/^\[ethjs-query\] while formatting outputs from RPC '(.*)'$/);
        if (m?.[1]) {
          const e = JSON.parse(m[1]);
          this.txResult = {
            error: {
              message: Object.values(e?.value?.data?.data)?.[0]?.reason
            }
          };
          if (!this.txResult.error.message) {
            this.txResult.error.message = e?.value?.data?.message || 'An error occurred.';
          }
        } else {
          this.txResult = { error };
        }
      }
      this.showPending = false;
      this.showResult = true;
    },
    async onCancel () {
      this.$router.push('/');
    },
    onReset () {
      this.showResult = false;
      this.txResult = null;
    }
  },
  mounted () {
    this.newPrice = this.fromWei(this.lastWord.price);
  }
};
</script>
<style lang="scss" scoped>
  @import '@/assets/css/util.scss';

  .last-word--form {
    fieldset {
      border: none;
    }

    section {
      display: flex;
      flex-direction: column;
      margin: toRem(4) toRem(0);
    }

    section.btn-bar {
      flex-direction: row;
    }
    .btn.submit {
      width: 75%;
      background-color: rgba(0,0,0,0.75);
      color: white;
    }
    .btn.submit[disabled] {
      background-color: rgba(0,0,0,0.5);
      color: black;
    }
    .btn.cancel {
      width: 25%;
    }
    label {
      font-family: RobotoMono;
      text-align: left;
    }
    .price-input--wrapper {
      margin-top: toRem(8);
      margin-bottom: toRem(8);
      text-align: left;

      .price-input {
        display: inline-block;
        padding: 0;
        font-size: toRem(18);
        border-top: none;
        border-left: none;
        border-right: none;
        width: 100%;
      }
      span.eth {
        margin-left: toRem(-40);
        font-size: toRem(18);
        font-weight: 700;
      }
    }
  }
  .result-frame {
    padding: toRem(16);
    button {
      display: block;
      margin: toRem(32) auto 0;
    }
  }
  .pending {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.75);

    .loader__wrap {
      display: flex;
      height: 100%;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .loader {
        border: toRem(10) solid #b9b7b7;
        border-top: toRem(10) solid #e3ebf5;
        border-radius: 50%;
        width: toRem(60);
        height: toRem(60);
        animation: spin 2s linear infinite;
      }
    }
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }

</style>
