import { createApp } from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';
import store from './store';
import './assets/css/main.scss';

import Home from './pages/home';
import About from './pages/about';
import Buy from './pages/buy';

const routes = [
  { path: '/', component: Home, name: 'home' },
  { path: '/about', component: About, name: 'about' },
  { path: '/buy', component: Buy, name: 'buy' }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (!store.state.blockchain.connected && to.name !== 'home') {
    return next({ name: 'home', query: { r: to.fullPath } });
  }
  // if (to.name === 'Game') {
  //   const game = store.state.chain?.games?.get(to.params.id);
  //   if (!game) {
  //     return next({ name: 'E404' });
  //   }
  // }

  next();
});

createApp(App)
  .use(store)
  .use(router)
  .mount('#app');
