<template>
  <div class="last-word--page">
    <div v-if="chain">
      <div v-if="connected">
        {{chain.name}}
        <span v-if="readonly">(Read Only)</span>
      </div>
    </div>
    <div v-else>
      Unsupported Network
    </div>
    <div class="title-wrap">
      <h1>The Last Word</h1>
      <img src="@/assets/img/bullhorn.svg" width="80" height="80" />
    </div>
    <div v-if="!connected">
      <button class="btn" @click="connect" :disabled="!available">Connect</button>
    </div>
    <div v-else>
      <div v-if="lastWord.contract.instance === null">
        <p>{{lastWord.contract.error}}</p>
        <div>
          <img class="no-joy" src="@/assets/img/sentiment_very_dissatisfied_black_48dp.svg" />
        </div>
      </div>
      <div v-else class="body">
        <div class="last-word--content">
          <p>"{{lastWord.content}}"</p>
        </div>
        <p>Written by</p>
        <p v-if="lastWord.author === account">You</p>
        <p v-else><span class="address">{{lastWord.author}}</span></p>
        <p><span class="date">{{contentUpdatedAt}}</span></p>
        <div v-if="readonly">
          <p>The Last Word can be yours</p>
          <p>For Only</p>
          <p>
            <span class="eth">{{fromWei(lastWord.price)}} ETH</span>
          </p>
          <span>But first you need to get on <a href="https://metamask.io" target="_blank">the right browser</a>.</span>
        </div>
        <div v-else class="cta">
          <p>The Last Word can be yours</p>
          <p>For Only</p>
          <p>
            <span class="eth">{{fromWei(lastWord.price)}} ETH</span>
          </p>
          <button class="btn" @click="onBuy">
            <span>Have the Last Word</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { defineComponent } from '@vue/composition-api'
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
  name: 'Home',
  computed: {
    ...mapGetters({
      available: 'blockchain/available',
      readonly: 'blockchain/readonly',
      chain: 'blockchain/chain',
      fromWei: 'blockchain/fromWei'
    }),
    ...mapState({
      connected: state => state.blockchain.connected,
      account: state => state.blockchain.account,
      lastWord: state => state.blockchain.lastWordContract
    }),
    contentUpdatedAt: function () {
      return (new Date(this.lastWord.contentUpdatedAt * 1000)).toLocaleString();
    }
  },
  watch: {
    connected: function (v) {
      if (v) {
        if (this.$route.query.r) {
          this.$router.replace(this.$route.query.r);
        }
      }
    }
  },
  data () {
    return {
    };
  },
  methods: {
    ...mapActions({
      connect: 'blockchain/connect',
      setLastWord: 'blockchain/lastWordContract/setLastWord'
    }),
    onBuy () {
      this.$router.push('/buy');
    }
  },
  setup () {

  },
  mounted () {
    if (
      (window?.ethereum?.isConnected()) ||
      (this.available && this.readonly)
    ) {
      this.connect();
    }
  }
};
</script>
<style lang="scss" scoped>
  @import '@/assets/css/util.scss';
  .title-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    h1 {
      line-height: toRem(60);
    }
  }
  img.no-joy {
    width: toRem(200);
    height: toRem(200);
    stroke-width: toRem(12);
  }
  .body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .last-word--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: RobotoSlab;
    background-color: black;
    color: white;
    min-height: toRem(140);

    p {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .cta {
    background-color: lightgray;
    padding-top: toRem(16);
    padding-bottom: toRem(16);
    p {
      margin-top: 0;
      margin-bottom: 0;
      line-height: toRem(26);
    }
    .btn {
      margin-top: toRem(16);
      background-color: rgb(197, 7, 17);
      font-family: RobotoMono;
      font-size: toRem(18);
      padding: toRem(16);
      font-weight: 700;
      color: white;
      border-radius: toRem(32);
    }
    .eth {
      margin-top: toRem(8);
      display: block;
    }
  }

  .eth {
    font-weight: 700;
    font-size: toRem(20);
  }

  @media only screen and (min-height: toRem(740)) {
    .last-word--content {
      min-height: toRem(200);
    }
  }

  @media only screen and (min-height: toRem(926)) {
    .title-wrap {
      margin-top: toRem(40);
      margin-bottom: toRem(40);
    }
  }

  @media only screen and (min-width: toRem(800)) and (min-height: toRem(926)) {
    .title-wrap {
      h1 {
        font-size: toRem(36);
      }
      img {
        width: toRem(100);
        height: toRem(100);
      }
    }
  }

</style>
