<template>
  <div class="last-word--page">
    <h1>What is The Last Word?</h1>
    <p>
      To put it simply, a place on the internet where you can spend some Ether
      to have the last word on pretty much anything you want.... that is until
      someone is willing to pay more....
    </p>
    <h2>How does it work?</h2>
    <ol>
      <li>You pay at least the current price to have the last word.</li>
      <li>The world sees your Last Word.</li>
      <li>The price for the next person (or you) to change the last word goes up by at least a little bit.</li>
      <li>If someone else pays it then <strong><em>the Ether goes to YOU!!!</em></strong></li>
    </ol>
    <h2>Can I really say anything?</h2>
    <p>
      Almost. Pretty much anything is fair game except..
    </p>
    <ul>
      <li>Doxxing/putting personal info out there about yourself or others.</li>
      <li>Calls for violence against groups or individuals.</li>
    </ul>
    <h2>What happens if I do not comply?</h2>
    <p>Your words will be redacted and you will have foolishly wasted your Ether.</p>
    <h2>This almost seems too good to be true!</h2>
    <p>Yes.</p>
  </div>
</template>
<script>
export default {
  name: 'About'
};
</script>
<style lang="scss" scoped>
  .last-word--page {
    text-align: left;
    p,li {
      font-family: RobotoSlab;
      font-weight: 300;
    }
  }
</style>
