import { createStore } from 'vuex';
import blockchain from './blockchain';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    blockchain
  }
});
