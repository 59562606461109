<template>
  <div class="last-word--page_header">
    <div class="nav">
      <router-link v-if="showHome" to="/">&lt; Home</router-link>
    </div>
  </div>
  <router-view />
  <div class="last-word--page__footer">
    <router-link v-if="showAbout" to="/about">What is the Last Word?</router-link>
  </div>
</template>

<script>
// import Home from './pages/home';

export default {
  name: 'App',
  components: {
    // Home
  },
  computed: {
    showHome: function () {
      return this.$route.name !== 'home';
    },
    showAbout: function () {
      return this.$route.name === 'home';
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
