import Web3 from 'web3';
const artifact = require('./LastWord.json');
const toHex = (v) => {
  if ((v === null) || (v === undefined)) {
    return v;
  }
  return Web3.utils.toHex(v).replace(/^0x0+/, '0x').replace(/x$/, 'x0');
};

export default {
  namespaced: true,
  state: {
    contract: {
      instance: null,
      error: null
    },
    validator: null,
    inflationRate: null,
    price: null,
    author: null,
    contentKey: null,
    content: null,
    contentUpdatedAt: null,
    paused: null
  },
  mutations: {
    setContract (state, data) {
      console.log('setContract', data);
      state.contract.instance = data.contract;
      state.contract.error = data.error;
    },
    setContractData (state, data) {
      console.log('setContractData', data);
      state.validator = data.validator;
      state.inflationRate = data.inflationRate;
      state.price = data.price;
      state.author = data.author;
      state.contentKey = data.contentKey;
      state.content = data.content;
      state.contentUpdatedAt = data.contentUpdatedAt;
      state.paused = data.paused;
    }
  },
  actions: {
    async setLastWord ({ rootGetters, state, dispatch }, { content, author, price }) {
      console.log('setLastWord:', { content, author, price });
      const instance = state.contract.instance;
      const chain = rootGetters['blockchain/chain'];
      const endpoint = `/api/lastword/v1/content/${chain.network}`;

      let r = await fetch(endpoint, {
        method: 'post',
        body: JSON.stringify({ content, author }),
        headers: { 'Content-Type': 'application/json' }
      });
      if (r.status !== 200) {
        const msg = await r.text();
        throw new Error(`storeContent failed: ${msg}`);
      }
      const { contentKey, signature, data } = (await r.json());
      console.log('setLastWord:', { contentKey });
      r = await instance.methods.setLastWord(contentKey, data, signature)
        .send({ from: author, value: price });
      console.log(r);

      await dispatch('refreshContractState');

      return r;
    },
    async refreshContractState ({ rootGetters, state, commit }) {
      let data = {
        validator: null,
        inflationRate: null,
        price: null,
        author: null,
        content: null,
        contentKey: null,
        contentUpdatedAt: null,
        paused: null
      };
      if (!state.contract.instance) {
        commit('setContractData', data);
        return;
      }
      const instance = state.contract.instance;
      const [validator, inflationRate, price, author, contentKeyRaw, contentUpdatedAt, paused] = await Promise.all([
        instance.methods.validator().call(),
        instance.methods.inflationRate().call(),
        instance.methods.price().call(),
        instance.methods.author().call(),
        instance.methods.contentKey().call(),
        instance.methods.contentUpdatedAt().call(),
        instance.methods.paused().call()
      ]);
      const chain = rootGetters['blockchain/chain'];
      let content = null;
      const contentKey = toHex(contentKeyRaw);
      if (chain) {
        const endpoint = `/api/lastword/v1/content/${chain.network}/${contentKey}`;
        const r = await fetch(endpoint, { method: 'get' });
        if (r.status !== 200) {
          const msg = await r.text();
          console.error(`getContent failed: ${msg}`);
        } else {
          content = (await r.json()).content;
        }
      }
      data = {
        validator,
        inflationRate,
        price,
        author,
        contentKey,
        contentUpdatedAt: Number(contentUpdatedAt),
        paused,
        content
      };

      commit('setContractData', data);
      return data;
    },
    async refreshContract ({ commit, rootState }) {
      const { web3, chainId } = rootState.blockchain;
      const { abi, networks } = artifact;
      const net = networks[chainId];
      console.log('refreshContract', {
        chainId,
        net
      });
      let contract = null;
      let error = null;

      if (net) {
        const code = await web3.eth.getCode(net.address);
        if (code !== '0x') {
          contract = new web3.eth.Contract(abi, net.address);
        } else {
          error = `Unable to locate contract at address ${chainId}:${net.address}.`;
        }
      } else {
        error = 'Unavailable on this network.';
      }

      commit('setContract', { contract, error });
    },
    async refreshState ({ dispatch }) {
      await dispatch('refreshContract');
      await dispatch('refreshContractState');
    }
  },
  modules: {

  }
};
